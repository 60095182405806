import React, { useState } from "react"
import loadable from "@loadable/component"
import { makeStyles } from "@material-ui/core/styles"
import "../../css/typography-muli.css"
const ChatComponent = loadable(() =>
  import("../../components/prod/custom-page")
)

const useStyles = makeStyles({
  circle: {
    position: "absolute",
    height: "385px",
    width: "489px",
    bottom: 0,
    right: "2%",
  },
  dots: {
    position: "absolute",
    left: "10%",
    top: "100px",
  },
})

function Chat() {
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  const classes = useStyles()
  // TODO: set
  const origDynamicStyleOptions = {
    hideSendBox: true,
  }
  const [hideSendBox, setHideSendBox] = useState(
    origDynamicStyleOptions.hideSendBox
  )

  function onHandleRestart() {
    setHideSendBox(origDynamicStyleOptions.hideSendBox)
  }

  return (
    <ChatComponent
      name="[regio iT] Bonn - Bürgerportal"
      pageTitle="Convaise - Bonn - Bürgerportal"
      // homepage="#"
      token="eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzUxNTYxMzkuMDE2OTM5LCJleHAiOjIzMDU4NzYxMzkuMDE2OTM5LCJib3RJZCI6IjFjNm85OWJza3J1eGdhcTIiLCJpc3MiOiJjb252YWlzZSIsImF1ZCI6ImNvbnZhaXNlLWNvdXJpZXIifQ.pgRbdRUTDb5rmQ3FsVK4LqZxHMPTJLrrXFsiIgfJ5Hx6dS6pk4yremRDwwhjNWls480-ESOMvAgz4PYxFg2GwWtkgeJ6bIrk4hOPPNeok4SW0wYEY-yUuAst-WpFPIHwlzkaUAdFZXNQAEbXSkDe33BEWgT47Fyah1iuvWTKvpiOGI3YYF-z3i95uazJ7yGr7LdUoSn9cf--EhWbSgG0a8rJFa1wXyJTo25dRHLK1DQ1fKwzAfYzf9CRRFH-sYTtAxHISR-t8oydGBTFsrbVoIDOWEMbc6oq8ymbIEFWwm21L0Yvki_kXtjjCgxjwQz31qIo_1cMm7re_87ooHyKKztTFxrICSqp7rzNkkwJZlnB5th24_TyAgFXijlaK7ZBREqZrPeg4NUyPlB9dgC0szLq5_Lagv9MucN6GNFREYm-aXZAdFKWjDEJbz--8uLd_wVtnDdExwSHIMir-yfWghXBobIjqYf2XHBBmokofMBDCJPzbYmCKZIzBTiAqRfM1djrtsi2y2fNzPViFhSDR1eEJhK3E6sbnM-fkesb1oZ-U23gJoJveRv2irhZQr03JUZxlH0If_UhE0DirbfRFlByRK2rdJ3KI9JrmM6jp87fKtN403MOgoQXMvBiCbh7knk5S4RrhP15brU0yd2w4AELLY4_9drd1QE827-lsjE"
      botId="1c6o99bskruxgaq2"
      domain="https://courier.convaise.com/frontend/v3/directline"
      avatar={false}
      avatarInitials={false}
      logo="https://service.bonn.de/documents/33843/0/BON_Logo_Stadt_blau_00008b-.png"
      // domain="https://europe.directline.botframework.com/v3/directline"
      origDynamicStyleOptions
      onHandleRestart={onHandleRestart}
      styleOptions={{
        primaryFont: "'Noto Sans', 'sans-serif'",
        bubbleFromUserTextColor: "white",
        bubbleTextColor: "#293754",
        bubbleFromUserBackground: "#134d88",
        botAvatarBackgroundColor: "#fff",
        hideSendBox,
        // primaryFont:
        //   '"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      }}
      styleCustomizations={{
        "--button-blue": "#134d88",
        "--button-blue-light": "#5a82ac",
        // "--button-red": "rgb(120, 125, 141)",
        // "--button-red-light": "rgba(120, 125, 141, 0.8)",
      }}
      onHideSendBox={value => {
        // console.log("changing hideSendbox")
        setHideSendBox(value)
      }}
      backgroundColor="#F0F4F5"
      customs={[
        <img
          src="https://static.convaise.com/demos/gegenbauer/gegenbauer-circle.svg"
          data-src="https://static.convaise.com/demos/gegenbauer/gegenbauer-circle.svg"
          className={classes.circle}
          role="presentation"
          alt=""
          key="customization-circle"
        />,
        <img
          src="https://static.convaise.com/demos/gegenbauer/gegenbauer-dots.svg"
          data-src="https://static.convaise.com/demos/gegenbauer/gegenbauer-dots.svg"
          className={classes.dots}
          key="customization-dots"
          role="presentation"
          alt=""
        />,
      ]}
      // css="https://static.convaise.com/webchat/gegenbauer/convaise-assistant-gegenbauer-iframe-min.css"
    />
  )
}

export default Chat
